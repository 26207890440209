import React, {Component} from 'react';

class ClientItem extends Component{
    render(){
        var {Url, Image} = this.props;
        return(
            <div className="item">
                <a href={Url}><img src={require("../../assets/img/clients-logo/" + Image)} alt="" /></a>
            </div>
        )
    }
}
export default ClientItem;