import React, {Component} from 'react';
import FTAboutWD from './FTAboutWD';
import FooterData from '../Elements/FooterData';
import FTLinkWD from './FTLinkWD';
import FTContactWD from './FTContactWD';
import FTSubscribeWD from './FTSubscribeWD';


class Footer extends Component{
    render(){
        var {CPLeft, CPRight} = this.props;
        return(
            <footer className="footer_area">
                <div className="container">
                    <div className="row footer_widget_area">
                        <FTAboutWD Image="f-logo.png" FooterData={FooterData} />
                        <FTContactWD Address={<><strong>Wonder Digital Media AS</strong><br/>Gabriel Scotts vei 37<br/>4818 FÆRVIK</>} Number="" Email="post@wonder.no" />
                        <FTLinkWD FooterData={FooterData} />
                        <FTSubscribeWD />
                    </div>
                </div>
                <div className="footer_copyright">
                    <div className="container">
                        <div className="copyright_inner text-center">
                            <div className="left_side">
                                <p>{CPLeft} {(new Date().getFullYear())}. {CPRight}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}
export default Footer;