import React,{useState} from 'react'
import axios from 'axios'

export default function RequestForm({BtnText}){

    const [submitted,setSubmitted] = useState(false);
    const [name,setName] = useState("");
    const [phone,setPhone] = useState("");
    const [email,setEmail] = useState("");
    const [msg,setMsg] = useState("");
    const [choice,setChoice] = useState("");

    const handleSubmit = () => {

        const data = {
            site: 'refinansiere.info',
            choice,
            name,
            phone,
            email,
            msg,
        };

        let formData = new FormData();
        formData.append('site', 'refinansiere.info');
        formData.append('choice', choice);
        formData.append('name', name);
        formData.append('phone', phone);
        formData.append('email', email);
        formData.append('msg',msg);

        const config = {
            headers: { 'content-type': 'multipart/form-data' }
        }

        axios.post(`https://app.gjerrigknark.com/data/refinans/?action=subscribe`,formData,config)
            .then(res => {
                setSubmitted(true)
            })
    }

    return(
        <form className="row request_form" id="contactForm">
            {!submitted &&
                <>
                    <div className="form-group col-md-12">
                        <select onChange={(e) => {setChoice(e.target.value)}} className="form-control" id="refinance_choice" name="refinance_choice">
                            <option value={"0"}> -- Velg type refinansiering -- </option>
                            <option value={"refinansiere-boliglån"}>Refinansiering av boliglån</option>
                            <option value={"refinansiere-forbrukslån"}>Refinansiering av forbrukslån</option>
                            <option value={"refinansiere-kredittkort"}>Refinansiering av kredittkortgjeld</option>
                            <option value={"refinansiere-kredittkort"}>Refinansiering av billån</option>
                            <option value={"refinansiere-kredittkort"}>Refinansiering ifm oppussing</option>
                            <option value={"annet"}>Annet</option>
                        </select>
                    </div>
                    <div className="form-group col-md-12">
                        <input onChange={(e) => {setName(e.target.value)}} type="text" className="form-control" id="name" name="name" placeholder="Ditt navn" val={name} />
                    </div>
                    <div className="form-group col-md-12">
                        <input type="text" onChange={(e) => {setPhone(e.target.value)}} className="form-control" id="phone" name="phone" placeholder="Mobilnummer" val={phone}/>
                    </div>
                    <div className="form-group col-md-12">
                        <input type="email" onChange={(e) => {setEmail(e.target.value)}} className="form-control" id="email" name="email" placeholder="E-postadresse" val={email}/>
                    </div>
                    {/*<div className="form-group col-md-6">
                        <input type="text" className="form-control" id="subject" name="subject" placeholder="Beskrivelse av behov" />
                    </div>*/}
                    <div className="form-group col-md-12">
                        <textarea onChange={(e) => {setMsg(e.target.value)}} className="form-control" name="message" id="message" rows="1" placeholder="Beskrivelse av behov">{msg}</textarea>
                    </div>
                </>
            }
            <div className="form-group col-md-12">
                {!submitted &&
                    <button onClick={handleSubmit} className="btn submit_btn floatRight" type="button" id="button-addon2">{BtnText} <strong className="icon"><i className="fa fa-paper-plane" aria-hidden="true"></i></strong></button>
                }
                {submitted &&
                    <span style={{fontSize:'170%'}}>Skjemaet ble sendt inn!</span>
                }
            </div>
        </form>
    )
}
