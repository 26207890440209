import React, {useState} from 'react';
import Address from '../components/MenuTopBar/Address';
import EmailAddress from '../components/MenuTopBar/EmailAddress';


export default function HeaderTopBar(){

	const [menuExpanded,setMenuExpanded] = useState(false);

	return(
		<header className="header_area menu_five">
			<div className="top_logo">
				<div className="container">
					<div className="row m0 justify-content-between">
						<div className="left_logo headerLogo">
							<a href="/">
								Refinansiere.info
							</a>
						</div>
						<div className="right_logo headerCta">
							{/*<Address Icon="map-marker" Address="B - 562, Mallin Street New York, West Col, NY 100 254" />
							<EmailAddress Icon="phone" Number="1800 456 7890" Email="rockybd1995@gmail.com" />*/}
							<a href={"/sok-om-refinansiering"} className={"button"}>Søk om refinansiering &raquo;</a>
						</div>
					</div>
				</div>
			</div>
			<div className="main_menu">
				<div className="container">
					<nav className="navbar navbar-expand-lg navbar-light bg-light">
						<div className={"headerLogo logo2"}>
							<a href="/">Refinansiere.info</a>
						</div>

						<button onClick={()=>{setMenuExpanded(!menuExpanded)}} className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
							<span></span>
						</button>
						<div className={"navbar-collapse"+(menuExpanded?"":" collapse")} id="navbarSupportedContent">
							<ul className="nav navbar-nav menu_nav mr-auto">
							<li><a className={"noLink"}>Refinansiering av:</a></li>
								<li className="dropdown submenu">
									<a className="dropdown-toggle" data-toggle="dropdown" href=".#" role="button" aria-haspopup="true" aria-expanded="false">Boliglån</a>
									{/*<ul className="dropdown-menu">
										<li><a href="/boliglan/refinansiering">Refinansiering boliglån</a></li>
										<li><a href="/Refinansiere-forbrukslan">Hvorfor refinansiere boliglån?</a></li>
										<li><a href="/Refinansiere-billan">Hvordan refinansiere boliglån?</a></li>
										<li><a href="/Refinansiere-kredittkort">Kalkulator refinansiering boliglån</a></li>
									</ul>*/}
								</li>

								<li className="dropdown submenu">
									<a className="dropdown-toggle" data-toggle="dropdown" href=".#" role="button" aria-haspopup="true" aria-expanded="false">Forbrukslån</a>
									{/*<ul className="dropdown-menu">
										<li><a href="/boliglan/refinansiering">Refinansiering av boliglån</a></li>
										<li><a href="/Refinansiere-forbrukslan">Forbrukslån</a></li>
										<li><a href="/Refinansiere-billan">Billån</a></li>
										<li><a href="/Refinansiere-kredittkort">Kredittkort</a></li>
									</ul>*/}
								</li>
								<li className="dropdown submenu">
									<a className="dropdown-toggle" data-toggle="dropdown" href=".#" role="button" aria-haspopup="true" aria-expanded="false">Kredittkort</a>
									{/*<ul className="dropdown-menu">
										<li><a href="/Pages/Blog">Blog with sidebar</a></li>
										<li><a href="/Pages/BlogTwoColumn">Blog 2 column</a></li>
										<li><a href="/Pages/SingleBlog">Blog details</a></li>
									</ul>*/}
								</li>
								<li className="dropdown submenu">
									<a className="dropdown-toggle" data-toggle="dropdown" href=".#" role="button" aria-haspopup="true" aria-expanded="false">Billån</a>
									{/*<ul className="dropdown-menu">
										<li className="dropdown submenu">
											<a className="dropdown-toggle" data-toggle="dropdown" href=".#" role="button" aria-haspopup="true" aria-expanded="false">Gallery</a>
											<ul className="dropdown-menu">
												<li><a href="/Pages/Gallery">-  Gallery Classic</a></li>
												<li><a href="/Pages/GalleryFullwidth">-  Gallery Two Column</a></li>
											</ul>
										</li>
										<li className="dropdown submenu">
											<a className="dropdown-toggle" data-toggle="dropdown" href=".#" role="button" aria-haspopup="true" aria-expanded="false">Our Team</a>
											<ul className="dropdown-menu">
												<li><a href="/Pages/Team">Team</a></li>
												<li><a href="/Pages/SingleTeam">Team Single</a></li>
											</ul>
										</li>
									</ul>*/}
								</li>

								<li><a href="#">Oppussing</a></li>

							</ul>
							<ul className="nav navbar-nav navbar-right">
								{/*<li className="search_icon">
									<div className="input-group">
										<input type="text" className="form-control" placeholder="Enter Keywords" />
										<div className="input-group-append">
											<button className="btn" type="button" id="button-addon2"><i className="icon icon-Search"></i></button>
										</div>
									</div>
								</li>*/}
							</ul>
						</div>
					</nav>
				</div>
			</div>
		</header>
	);
}
