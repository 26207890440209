import React, {Component} from 'react';
import ClientItem from './ClientItem';

class ClientSlider extends Component{
    render(){
        return(
            <section className="client_logo_area">
                <div className="container">
                    <div className="clients_slider owl-carousel">
                        <ClientItem Url="#" Image="client-1.png" />
                        <ClientItem Url="#" Image="client-2.png" />
                        <ClientItem Url="#" Image="client-3.png" />
                        <ClientItem Url="#" Image="client-4.png" />
                        <ClientItem Url="#" Image="client-5.png" />
                    </div>
                </div>
            </section>
        )
    }
}
export default ClientSlider;