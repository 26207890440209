import React, {useState} from 'react';

import Title from './Title';
import RequestForm from './RequestForm';
import AccordionElement from "./widgets/AccordionElement";

export default function RequestQuote(){
    const [accordionOpen,setAccordionOpen] = useState({})

    return(
        <section className="request_area p_100">
            <div className="container">
                <div className="row request_inner">
                    <div className="col-lg-6">
                        <Title Title="INNHENT TILBUD PÅ REFINANSIERING" />
                        <RequestForm BtnText="Send inn" />
                    </div>
                    <div className="col-lg-6">
                        <div className="accordion service_plan" id="accordionExample">

                            <AccordionElement
                                headline={"Hva betyr det å refinansiere et lån?"}
                                text={"Kort fortalt så erstatter du et gammelt lån med et nytt lån, som oftest med bedre betingelser."}
                                expand={true}
                            />
                            <AccordionElement
                                headline={"Hvorfor refinansiere lån?"}
                                text={"Det er flere grunner til å ønske å refinansiere et lån. Eksempelvis så kan du ønske bedre eller endrede betingelser som lavere rente, endret månedsbeløp eller å øke totalt lånebeløp."}
                                expand={false}
                            />
                            <AccordionElement
                                headline={"Hvordan refinansiere lån?"}
                                text={"I prakis så søker du om et nytt lån som skal erstatte ditt gamle lån. Det nye lånet nedbetaler så ditt gamle lån og du står igjen med det nye lånet og de nye betingelsene som er knyttet til det nye lånet."}
                                expand={false}
                            />
                            <AccordionElement
                                headline={"Trenger jeg sikkerhet i bolig?"}
                                text={"I mange tilfeller lønner det seg å kunne stille ledig sikkerhet i eiendom når man ønsker å refinansiere et lån.\n" +
                                    "                                        Det finnes dog også banker som spesialiserer seg på refinansiering uten sikkerhet, men man vil da i de fleste tilfeller\n" +
                                    "                                        få noe dårligere betingelser enn dersom man kunne stille sikkerhet."}
                                expand={false}
                            />
                            <AccordionElement
                                headline={"Når lønner det seg å refinansiere?"}
                                text={"Dersom du har ugunstig høy rente eller det er andre faktorer som gjør at ditt nåværende lån ikke er konkurransedyktig vil det lønne seg å refinansiere."}
                                expand={false}
                            />
                            <AccordionElement
                                headline={"Hvordan vet jeg at jeg får bedre betingelser?"}
                                text={"Du vet ikke alltid at du vil få bedre betingelser. Derfor kan det lønne seg å kontakte flere banker og høre hva deres betingelser er. Når du forhører deg om/søker om et refinansieringslån betyr ikke at du er forpliktet til å gjennomføre refinansieringen, det gjør du kun om du er fornøyd med de nye betingelsene."}
                                expand={false}
                            />
                            <AccordionElement
                                headline={"Er det noen som kan hjelpe meg med å refinansiere?"}
                                text={"Det finnes ulike tjenester/finansagenter som har spesialisert seg på å udnersøke hos flere banker om du kan få lån med gunstigere betingelser."}
                                expand={false}
                            />
                            <AccordionElement
                                headline={"Er det uforpliktende å søke om refinansiering?"}
                                text={"Ja. Du står fritt til å søke om et refinansieringslån og så takke nei om du ikke synes tilbudet er godt nok."}
                                expand={false}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}