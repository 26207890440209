import React, {Component} from 'react';


class Breadcrumb extends Component{
    render(){
        var {PageName, PageLink} = this.props;
        return(
            <section className="breadcrumb_area">
                <div className="container">
                    <div className="breadcrumb_inner">
                        {PageName.length > 0 &&
                            <>
                                <h2>{PageName}</h2>
                                <div className="link">
                                    <a href="../">Hjem</a>
                                    <a href={"../Pages/" + PageLink}>{PageName}</a>
                                </div>
                            </>
                        }
                        {PageName.length == 0 &&
                            <>
                                <div style={{height:'max(100px,13vw)'}}>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </section>
        );
    }
}
export default Breadcrumb;