import React, {Component} from 'react';

class FTAboutWD extends Component{
    render(){
        let FooterData = this.props.FooterData; 
        var {Image} = this.props;
        return(
            <div className="col-lg-3 col-md-6">
                <aside className="f_widget about_widget">
                    {/*<img src={require("../../assets/img/" + Image)} alt="" />*/}
                    <div className="footerLogo">
                        <a href="/">
                            {/*<img src={require('../assets/img/' + this.props.Logo)} alt=""/>*/}
                            Refinansiere.info
                        </a>
                    </div>
                    <p>
                        Refinansiere.info er en tjeneste som tilbyr informasjon om ulike former for refinansiering.
                        Merk at enkelte av lenkene på nettstedet kan være annonselenker.
                    </p>
                    <ul style={{display:'none'}} className="list f_social">
                    {
                        FooterData.AboutSocial.map(item =>{
                            return(
                                
                                <li key={item.id}><a href={item.url}><i className={item.icon}></i></a></li>
                            )
                        })
                    }
                    </ul>
                </aside>
            </div>
        );
    }
}
export default FTAboutWD;