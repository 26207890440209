import React, { Component } from 'react';

class EmailAddress extends Component{
    render(){
        var {Icon, Number, Email} = this.props;
        return(
            <div className="media">
                <div className="d-flex">
                    <i className={"fa fa-" + Icon} aria-hidden="true"></i>
                </div>
                <div className="media-body">
                <a href={"tel:" + Number} ><span>Call Us: </span>{Number}</a>
                <a href={"mailto:" + Email}><span>Email : </span> {Email}</a>
                </div>
            </div>
        );
    }
}
export default EmailAddress;