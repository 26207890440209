import React, {Component} from 'react';
import Title2 from './Title2';
import WorkItem from './WorkItem';

class RefinancingAreas extends Component{
    render(){
        return(
            <section className="work_process_area p_100">
                <div className="container">
                    <Title2 Title="HVORFOR REFINANSIERE?"
                            Subtitle={<>
                                        Å <strong>refinansiere et lån</strong> betyr rett og slett å erstatte det eksisterende lånet med et
                                        nytt lån med gunstigere betingelser. Det kan være seg grunnet bedre rente, behov for å øke lånebeløpet,
                                        lavere totalkostnader, endret nedbetalingstid m.m. Det kan være flere grunner til at man ønsker refinansiering:

                                    {/*<strong>Hvordan refinansiere:</strong> Avhengig av din egen forhandlingsposisjon kan du be om refinansiering hos
                                    aktøren som har utstedt det eksisterende lånet - eller du kan gå ut i det åpne markedet om du finner bedre betingelser
                                    annet steds hen.*/}


                            </>}
                    />
                    <div className="row work_process_inner">
                        <WorkItem
                            Icon="fa-users"
                            Title="Boliglån"
                            Description="Om du ser at banken boliglånet ditt er hos ikke tilbyr konkurransedyktige betingelser sammenlignet med andre aktører i markedet, eller om boligen din har steget i verdi slik at du kan få gunstigere betingelser så kan det lønne seg å refinansiere boliglånet."
                        />
                        {/*<WorkItem Icon="fa-cogs" Title="Fritidsbolig" Description="Evening do forming observe spirits is in. Country hearted be of justice sending. On so they as with room cold" />*/}
                        <WorkItem
                            Icon="fa-file"
                            Title="Oppussing"
                            Description="Å refinansiere boliglånet for å kunne gjennomføre oppussingsprosjekter i egen bolig kan i enkelte tilfeller være smart og lønnsomt. Særlig i det øyemed å oppnå en høyere verdsettelse og/eller salgspris enn om prosjektet ikke hadde blitt gjennomført."
                        />
                        <WorkItem
                            Icon="fa-pie-chart"
                            Title="Billån"
                            Description="I enkelte tilfeller kan det være en god ide å refinansiere et billån. Det kan være i sammenheng med salg av bilen i de tilfeller bilen som skal selges fungerer som pant eller for å omgjøre lånet til et mer langsiktig lån for å få ned månedskostnadene til billånet."
                        />
                        <WorkItem
                            Icon="fa-user-secret"
                            Title="Forbrukslån"
                            Description="Lånebetingelsene på forbrukslån varierer kolossalt. Om du kan erstatte forbrukslånet ditt med et refinansieringslån med gunstigere betingelser, det vil si lavere effektiv rente, vil det lønne seg. Eller du kan ønske å få ned månedskostnaden gjennom å endre løpetid."
                        />
                        <WorkItem
                            Icon="fa-check"
                            Title="Kredittkort"
                            Description="Utestående kredittkortgjeld over forfall er blant de aller dyreste formene for lån du kan ha. I de aller fleste tilfeller vil det lønne seg å refinansiere slik gjeld. I praksis vil en da erstatte kredittkortgjelden med et mer langsiktig lån med bedre betingelser."
                        />
                    </div>
                </div>
            </section>
        )
    }
}
export default RefinancingAreas;