import React, {Component} from 'react';

class Title2 extends Component{
    render(){
        var {Title, Subtitle} = this.props;
        return(
            <div className="main_title">
                <h2>{Title}</h2>
                <p>{Subtitle}</p>
            </div>
        )
    }
}
export default Title2;