import React, {Component} from 'react';


class Title extends Component{
    render(){
        var {ClassName, Title} = this.props;
        return(
            <div className={"single_title " + ClassName}>
            <h2>{Title}</h2>
            </div>
        );
    }
}
export default Title;