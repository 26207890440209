import React, {useState} from "react";

export default function AccordionElement({headline,text,expand}){

    const [expanded,setExpanded] = useState(expand?expand:false);

    return(
        <div className="card">
            <div className="card-header" id="headingOne">
                <button onClick={()=>{setExpanded(!expanded)}} className={"btn btn-link"+(expanded?"":" collapsed")} type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded={expanded?"true":"false"} aria-controls="collapseOne">
                    <h3 className={"inheritFont"}>{headline}</h3>
                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                    <i className="fa fa-angle-up" aria-hidden="true"></i>
                </button>
            </div>
            <div id="collapseOne" className={"collapse"+(expanded?" show":"")} aria-labelledby="headingOne" data-parent="#accordionExample">
                <div className="card-body">
                    {text}
                </div>
            </div>
        </div>
    )
}