import React, {Component} from 'react';

class WorkItem extends Component{
    render(){
        var {Title, Description, Icon} = this.props;
        return(
            <div className="col-lg-4 col-md-6">
                <div className="process_item">
                    <i className={"fa " + Icon}></i>
                    <a href=".#"><h4>{Title}</h4></a>
                    <p>{Description}</p>
                </div>
            </div>
        )
    }
}
export default WorkItem;