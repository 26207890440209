import React, {Component} from 'react';
import Image from "gatsby-plugin-sanity-image"

class Provider extends Component{
    render(){
        var {image, Title, Details, BtnText, Url, Ad} = this.props;
        return(
            <div className="col-lg-4 col-md-6">
                <div className="service_item">
                    <a style={{display:'block'}} target={"_blank"} rel={"noopener nofollow noreferrer"} href={Url}>
                        <div className="service_img">
                            {/*<img className="img-fluid" src={require("../../assets/img/service/" + Image)} alt="" />*/}
                            {Ad === true && <div className={"adNotice"}>Annonse</div>}
                                <Image
                                    alt={Title}
                                    {...image}
                                    width={370}
                                    height={200}
                                    style={{
                                        width: "min(100%,370px)",
                                        aspectRatio: "1.85",
                                        objectFit: "contain",
                                        objectPosition: "center center",
                                        border: "1px solid #efefef",
                                        padding: "20px"
                                    }}
                                />
                        </div>
                    </a>
                    <div className="service_text">
                        <a target={"_blank"} rel={"noopener nofollow noreferrer"} href={Url}><h4>{Title}</h4></a>
                        <p>{Details}</p>
                        {BtnText.length > 0 &&
                            <a className="main_btn" href={Url}>{BtnText} <strong className="icon"><i className="fa fa-repeat" aria-hidden="true"></i></strong></a>
                        }
                    </div>
                </div>
            </div>
        )
    }
}
export default Provider;