import React, { Component } from 'react';

class Address extends Component{
    render(){
        var {Icon, Address} = this.props;
        return(
            <div className="media">
                <div className="d-flex">
                    <i className={"fa fa-" + Icon} aria-hidden="true"></i>
                </div>
                <div className="media-body">
                    <p>{ Address }</p>
                </div>
            </div>
        );
    }
}
export default Address;