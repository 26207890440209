import React from 'react';
import {graphql,StaticQuery} from 'gatsby'
import Title2 from './Title2';
import Provider from './Provider';

const CompareRefinancing = () =>{

    return (
        <StaticQuery
            query={graphql`
            
                query ComparisonQuery {
                  allSanityRefinanceComparisonProvider{
                    edges {
                      node{
                        slug{
                            current
                        }
                        title
                        intro
                        url
                        example
                        image{
                            ...ImageWithPreview
                        }
                      }
                    }
                  }
                  
                  
                }`
            }
            render={data => (
                <section className="service_area p_100">
                    <div className="container">
                        <Title2 Title="SAMMENLIGN REFINANSIERING" Subtitle="Følgende sammenligningstjenster er finansagenter som kan hjelpe deg med å innhente tilbud på refinansiering. Som med alle andre former for lån lønner det seg å sammenligne og velge den banken som gir deg best tilbud." />
                        <div className="row service_inner">
                            {data.allSanityRefinanceComparisonProvider.edges.map(({node},i) => {
                                //console.log(node.image);
                                return(
                                    <Provider
                                        key={i}
                                        image={node.image}
                                        Title={node.title}
                                        Details={node.example}
                                        BtnText=""
                                        Url={node.url}
                                        Ad={true}
                                    />
                                )
                            })}
                        </div>
                    </div>
                </section>
            )}
        />
    )
}
export default CompareRefinancing;
