import React, {Component} from 'react';


class FTWDTitle extends Component{
    render(){
        return(
            <div className="f_title">
                <h3>{this.props.TitleText}</h3>
            </div>
        );
    }
}
export default FTWDTitle;