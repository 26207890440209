const FooterData = {
    AboutSocial: [
        {
            id: 1,
            url: 'https://www.facebook.com/',
            icon: 'fa fa-facebook',
        },
        {
            id: 2,
            url: 'https://twitter.com/',
            icon: 'fa fa-twitter',
        },
        {
            id: 3,
            url: 'https://www.instagram.com/',
            icon: 'fa fa-instagram',
        },
        {
            id: 4,
            url: 'https://www.pinterest.com/',
            icon: 'fa fa-pinterest',
        },
    ],
    PagesLink: [
        {
            id: 1,
            Text: 'Refinansiere boliglån',
            url: '#',
        },
        {
            id: 2,
            Text: 'Refinansiere forbrukslån',
            url: '#',
        },
        {
            id: 3,
            Text: 'Refinansiere kredittkort',
            url: '#',
        },
        {
            id: 4,
            Text: 'Refinansiere billån',
            url: '#',
        },
        {
            id: 5,
            Text: 'Refinansiering oppussing',
            url: '#',
        }
    ]
};
export default FooterData;
